<template>
  <div v-if="itemStorage" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="itemStorage">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Basisdaten</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('name')"
            label-for="name"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="itemStorage.name" type="text" :placeholder="$t('name')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('itemStorageType')"
            label-for="itemStorageType"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('itemStorageType') + ' ' + $t('select')"
              v-model="itemStorage.itemStorageType"
              :options="getEnumValues('ItemStorageType')"
              :show-labels="true"
              :allow-empty="false"
              :taggable="true"
              :selectLabel="$t('select')"
              :selectedLabel="$t('selected')"
              :deselectLabel="$t('remove_selection')"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><b-badge>{{ $t(option) }}</b-badge></template
              >
              <template slot="option" slot-scope="{ option }">
                <b-badge>
                  {{ $t(option) }}
                </b-badge></template
              >
            </multiselect>
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12"> </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showDeleteButton="buttons.showDeleteButton"
            :model="itemStorage"
            modelEditRoute="ItemStorageEditPage"
            modelListRoute="ItemStoragesPage"
            modelRouteParamName="itemStorageNumber"
            :updateCallback="updateItemStorage"
            :createCallback="createItemStorage"
            :deleteCallback="deleteItemStorage"
            :fetchPaginatedCallback="fetchItemStorages"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import ButtonBar from '@/components/ButtonBar.vue';

export default {
  name: 'ItemStorageEditPage',
  components: {
    Widget,
    Breadcrumbs,
    Multiselect,
    ButtonBar,
  },
  props: {
    itemStorageNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      filteredUsers: [],
    };
  },
  computed: {
    ...mapGetters(['getItemStorage', 'getItemStorageByNumber', 'getEnumValues', 'getUsers']),
    buttons() {
      return {
        showDeleteButton: this.itemStorage && this.itemStorage.number !== '_new' ? true : false,
      };
    },
    itemStorage() {
      return this.getItemStorageByNumber(this.number);
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('itemStorage'), route: { name: 'ItemStoragesPage' } },
        { name: this.itemStorage.number !== '_new' ? this.$t('edit') : this.$t('new') },
      ];
    },
  },
  methods: {
    ...mapActions([
      'fetchItemStorages',
      'fetchItemStorageByNumber',
      'updateItemStorage',
      'createItemStorage',
      'deleteItemStorage',
      'fetchEnumValues',
      'initItemStorage',
    ]),

    /**
     *
     */
    // async save(itemStorage) {
    //   if (itemStorage.number === '_new') {
    //     await this.createItemStorage(itemStorage);
    //   } else {
    //     await this.updateItemStorage(itemStorage);
    //   }
    // },
  },
  async mounted() {
    await this.fetchEnumValues('ItemStorageType');

    if (!this.itemStorage && this.itemStorageNumber) {
      this.number = this.itemStorageNumber;
      await this.fetchItemStorageByNumber(this.number);
    } else {
      this.initItemStorage();
      this.number = '_new';
    }
  },
};
</script>

<style scoped lang="scss"></style>
